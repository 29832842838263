<template>
    <div class="py-4 container-fluid">
        <div class="col-lg-6">
          <h4>Edit Role</h4>
        </div>
        <div class="mt-4 col-lg-8 mt-lg-0">
          <div class="card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Information</h5>
              <div class="row mt-4">
                <div class="col-12 col-sm-12">
                  <material-input v-model="detail.name_th" variant="dynamic"  />
                </div>
                <div class="col-12 col-sm-12">
                  <material-input v-model="detail.name_en" variant="dynamic"  />
                </div>
                <div class="col-12 col-sm-12 mt-4">
                <material-input v-model="detail.description" variant="dynamic" label="Description" />
                </div>
              </div>
                <material-button class="mt-2 ms-3 btn bg-gradient-info ms-lg-auto " @click="updateData" >
            Update
                </material-button>
            </div>
          </div>
        </div>
    </div>
        
       
  </template>
  
  <script>
  import MaterialInput from "@/components/MaterialInput.vue";
  import MaterialButton from "/src/components/MaterialButton.vue";
  import axios from 'axios';
  import Swal from 'sweetalert2';

  export default {
    name: "EditMat",
    components: { MaterialInput, MaterialButton  },
    data() {
      return {
        detail:{
            name_th: "",
            name_en: "",
            description:""
        }
      };
    },
    mounted() {
        this.fetchDetail();
    },
    methods:{
        async fetchDetail(){
        const result = await axios.get("https://uat-api.susenginetech.com/api/v1/role/"+this.$route.query.id)
        if (result.data.isError == true){
          alert(result.data.errorMessage)
          }
        else{
          this.detail=result.data.data
          console.log(this.detail);
          
        }
        
        },
        async updateData(){
        const payload = {
            name_th: this.detail.name_th,
            name_en: this.detail.name_en,
            description:this.detail.description
        }
         
        
        console.log(payload);
        
        const result = await axios.post("https://uat-api.susenginetech.com/api/v1/role/update/"+this.$route.query.id,payload)
        if(result.data.isError){
            Swal.fire({
              text: result.data.errorMessage,
              icon: "error"
            })
          }else{
            Swal.fire({
              title: "Successful",
              icon: "success"
            })
          }
      }
    }
   
  };
  </script>
  
  <style>
  .text-right {
    text-align: right;
  }
  .card {
    margin-bottom: 20px;
  }
  </style>
  