<template>
    <div class="container-fluid">
      <div class="page-header border-radius-xl mt-7">
        
      </div>
      <div class="card card-body mx-3 mx-md-4 mt-n6">
      
          <div class="col-auto">
          </div>
          <div class="col-auto my-auto">
            <h3 class="mb-3 ms-3">Appove Salary</h3>
            <p class="mb-0 ms-3 font-weight-normal text-sm">description</p>
          
        </div> 
        <div class="row">
          <default-statistics-card
            title="รายการทั้งหมด"
            count="20,000"
            :percentage="{ color: 'success' }"
          
          />
         
          <default-statistics-card
            title="ราคาสุทธิ"
            count="2,200,000.00 บาท"
            :percentage="{ color: 'success' }"
          
          />
          <div class="row">
      <default-statistics-card
            class="col col-lg-4"
            title="รายการทั้งหมดของปี"
            count="3,000 "
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
            class="col col-lg-4"
            title="มูลค่าอนุมัติต่อปีนี้"
            count="320,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
            class="col col-lg-4"
            title="มูลค่าอนุมัติต่อเดือนนี้"
            count="3,200.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
        </div>
      </div>
    </div>
      </div>
    <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">รายการที่ต้องอนุมัติเงินเดือน</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search0" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>Salary</th>
                  <th>OT</th>
                  <th>Bonus</th>
                  <th>Sum</th>
                  <th>Date</th>
                  <th>Status</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Tiger Nixon</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td><material-button class="bt btn-info">Appove</material-button>
                    <material-button class="ms-2 bt btn-info">Not approve</material-button></td>
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Herrod Chandler</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/05</td>
                  <td><material-button class="bt btn-info">Appove</material-button>
                    <material-button class="ms-2 bt btn-info">Not approve</material-button></td>
                  
                </tr>
               
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Brielle Williamson</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/07/25</td>
                  <td><material-button class="bt btn-info">Appove</material-button>
                    <material-button class="ms-2 bt btn-info">Not approve</material-button></td>
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Rhona Davidson</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/06/15</td>
                  <td><material-button class="bt btn-info">Appove</material-button>
                    <material-button class="ms-2 bt btn-info">Not approve</material-button></td>
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">หทัย เรือสุวรรณ</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td><material-button class="bt btn-info">Appove</material-button>
                    <material-button class="ms-2 bt btn-info">Not approve</material-button></td>
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                 </td>
                  <td class="text-sm font-weight-normal"> เปมิศา ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/12/02</td>
                  <td><material-button class="bt btn-info">Appove</material-button>
                    <material-button class="ms-2 bt btn-info">Not approve</material-button></td>
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Airi Satou</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2008/11/28</td>
                  <td><material-button class="bt btn-info">Appove</material-button>
                    <material-button class="ms-2 bt btn-info">Not approve</material-button></td>
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Cedric Kelly</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/03/29</td>
                  <td><material-button class="bt btn-info">Appove</material-button>
                    <material-button class="ms-2 bt btn-info">Not approve</material-button></td>
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Ashton Cox</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2009/01/12</td>
                  <td><material-button class="bt btn-info">Appove</material-button>
                    <material-button class="ms-2 bt btn-info">Not approve</material-button></td>
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Garrett Winters</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/07/25</td>
                  <td><material-button class="bt btn-info">Appove</material-button>
                    <material-button class="ms-2 bt btn-info">Not approve</material-button></td>
                  
                </tr>
                
               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>     
    
<div class="row">

          <default-statistics-card
            class="col col-lg-3  "
            title="รายการทั้งหมด"
            count="2,200"
            :percentage="{ color: 'success' }"
          
            
          />
          <default-statistics-card
            class="col col-lg-4"
            title="ราคาสุทธิทั้งปี"
            count="320,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
</div>
<div class="row">
  <h6 class="row">เดือน</h6>
          
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 1"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
            title="เดือน 2"
            count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 3"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 4"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 5"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 6"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 7"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 8"
          count="200,000.00 บาท"

            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 9"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 10"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 11"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 12"
          count="200,000.00 บาท"
          :percentage="{ color: 'success'}"
            
          />
    </div>
    <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="col-3 mb-4">
  <label for="choices-year" class="form-label ms-4">เลือกปี</label>
  <select
    id="choices-year"
    class="form-select ms-4"
    name="choices-year"
  >
    <option value="choich1">2567</option>
    <option value="choich2">2566</option>
    <option value="choich3">2565</option>
    <option value="choich4">2564</option>
    <option value="choich5">2563</option>
  </select>
</div>
          <div class="card-header">
            <h5 class="mb-0">รายการอนุมัติเงินเดือน</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search1" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>Salary</th>
                  <th>OT</th>
                  <th>Bonus</th>
                  <th>Sum</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Other</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Tiger Nixon</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                 </td>
                  <td class="text-sm font-weight-normal"> Herrod Chandler</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/05</td>
                  <td class="text-sm font-weight-normal">Appove</td>

                  
                </tr>
               
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Brielle Williamson</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/07/25</td>
                  <td class="text-sm font-weight-normal">Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Rhona Davidson</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/06/15</td>
                  <td class="text-sm font-weight-normal">Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">หทัย เรือสุวรรณ</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/12/02</td>
                  <td class="text-sm font-weight-normal">Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Airi Satou</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2008/11/28</td>
                  <td class="text-sm font-weight-normal">Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Cedric Kelly</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/03/29</td>
                  <td class="text-sm font-weight-normal">Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Ashton Cox</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2009/01/12</td>
                  <td class="text-sm font-weight-normal">Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Garrett Winters</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/07/25</td>
                  <td class="text-sm font-weight-normal">Appove</td>
                 
                  
                </tr>
                
               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>     
<div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">รายการไม่อนุมัติเงินเดือน</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search2" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>Salary</th>
                  <th>OT</th>
                  <th>Bonus</th>
                  <th>Sum</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Other</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Tiger Nixon</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">Not Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Herrod Chandler</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/05</td>
                  <td class="text-sm font-weight-normal">Not Appove</td>

                  
                </tr>
               
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Brielle Williamson</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/07/25</td>
                  <td class="text-sm font-weight-normal">Not Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                 </td>
                  <td class="text-sm font-weight-normal"> Rhona Davidson</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/06/15</td>
                  <td class="text-sm font-weight-normal">Not Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">หทัย เรือสุวรรณ</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">Not Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/12/02</td>
                  <td class="text-sm font-weight-normal">Not Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Airi Satou</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2008/11/28</td>
                  <td class="text-sm font-weight-normal">Not Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Cedric Kelly</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/03/29</td>
                  <td class="text-sm font-weight-normal">Not Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Ashton Cox</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2009/01/12</td>
                  <td class="text-sm font-weight-normal">Not Appove</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Garrett Winters</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/07/25</td>
                  <td class="text-sm font-weight-normal">Not Appove</td>
                 
                  
                </tr>
                
               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>     
    
    <div class="row">
          <default-statistics-card
            class="col col-lg-3  "
            title="รายการที่อนุมัติต่อปี"
            count="2,200"
            :percentage="{ color: 'success' }"
          
          />
          <default-statistics-card
            class="col col-lg-3"
            title="รายการที่อนุมัติต่อปี"
            count="320"
            :percentage="{ color: 'success'}"
            
          />
    </div>
    <div class="row">
          <default-statistics-card
            class="col col-lg-4"
            title="มูลค่าที่อนุมัติต่อปี"
            count="320,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
            class="col col-lg-3"
            title="มูลค่าที่ไม่อนุมัติต่อปี"
            count="320.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
</div>
  </template>
  <script>
  import MaterialAvatar from "/src/components/MaterialAvatar.vue";
  import MaterialButton from "/src/components/MaterialButton.vue";
  import DefaultStatisticsCard from "/src/views/dashboards/components/DefaultStatisticsCard.vue";
  import { DataTable } from "simple-datatables";
  import user from "/src/assets/icon/user.png";

  export default {
    name: "DataTables",
    components: {
  
      DefaultStatisticsCard,
      MaterialAvatar,
      MaterialButton
  

    },
    data() {
      return {
        user,
        imageUrl: require("@/assets/img/products/product-11.jpg"),
};
      },
    mounted() {
    

    // eslint-disable-next-line no-unused-vars
    const dataTableSearch = new DataTable("#datatable-search0", {
      searchable: true,
      fixedHeight: true,
    });
    // eslint-disable-next-line no-unused-vars
    const dataTableSearch1 = new DataTable("#datatable-search1", {
      searchable: true,
      fixedHeight: true,
    });
    // eslint-disable-next-line no-unused-vars
    const dataTableSearch2 = new DataTable("#datatable-search2", {
      searchable: true,
      fixedHeight: true,
    });
    
  },

      
};
  

</script>

<style>

</style>