<template>
    <h2 class="text-center mb-4">Profile Finance</h2>
<div class="container">
  <div class="card shadow-lg p-4">
    <div class="row g-4">
      <!-- Profile Information Section -->
      <div class="col-lg-6">
        <div class="text-center mb-4">
          <material-avatar
            :img="user"
            class="mb-4"
            alt="Avatar"
            size="xxl"
            border-radius="md"
          />
        </div>
        <h4 class="mb-3">ประวัติส่วนตัว</h4>
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><strong>First Name:</strong> Pemisa</li>
          <li class="list-group-item"><strong>Last Name:</strong> Trisuwan</li>
          <li class="list-group-item"><strong>Nickname:</strong> Butter</li>
          <li class="list-group-item"><strong>Identity card no:</strong> 11379988540356</li>
          <li class="list-group-item"><strong>Start work:</strong> 4 / 03 / 2024</li>
          <li class="list-group-item"><strong>Role:</strong> Staff</li>
          <li class="list-group-item"><strong>Position in company:</strong> Programmer</li>
          <li class="list-group-item"><strong>Level:</strong> [Add Level]</li>
          <li class="list-group-item"><strong>Phone Number:</strong> 089-9999999</li>
          <li class="list-group-item"><strong>Address:</strong> 17/4 Moo.5, Bamroongrat Road, Tambon Pibulsongkram, Amphur Muang, Bangkok, 10400</li>
          <li class="list-group-item"><strong>Email:</strong> xxxxx@gmail.com</li>
        </ul>
      </div>

      <!-- Financial Information Section -->
      <div class="col-lg-6">
        <div class="card shadow-sm h-100">
          <div class="card-header  text-white">
            <h5 class="mb-0">ข้อมูลการเงิน</h5>
          </div>
          <div class="card-body">
            <ul class="list-group list-group-flush">
              <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
          
          <default-statistics-card
            class="col col-lg-12  "
            title="OT"
            count="2,000.00 บาท"
            :percentage="{ color: 'success' }"
            :dropdown="dropdownOptions"
          />
        </li>
        <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
          
          <default-statistics-card
            class="col col-lg-12  "
            title="โบนัส"
            count="2,000.00 บาท"
            :percentage="{ color: 'success' }"
            :dropdown="dropdownOptions"
          /></li>
          <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
          <default-statistics-card
            class="col col-lg-12  "
            title="เงินที่ขอเบิก"
            count="2,000.00 บาท"
            :percentage="{ color: 'success' }"
            :dropdown="dropdownOptions"
          />
        </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


      
        <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">ประวัติการใช้เงิน</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search0" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>วันที่</th>
                  <th>รายการ</th>
                  <th>เงินเดือน</th>
                  <th>โอที</th>
                  <th>โบนัส</th>
                  <th>เงินที่ขอเบิก</th>
                  <th>รวมเงิน</th>
                  <th>อื่นๆ</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">Topic</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">3k</td>
                  <td class="text-sm font-weight-normal">234</td>
                 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
        </div>
        <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">รายการรออนุมัติ</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search1" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>วันที่</th>
                  <th>รายการ</th>
                  <th>โปรเจ็ค</th>
                  <th>ประเภท</th>
                  <th>มูลค่า</th>
                  <th>สถานะ</th>
                  <th>อื่นๆ</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">Topic</td>
                  <td class="text-sm font-weight-normal">Project Name</td>
                  <td class="text-sm font-weight-normal">Type</td>
                  <td class="text-sm font-weight-normal">10k</td>
                  <td class="text-sm font-weight-normal">appoved</td>
                  
                 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
        </div>
        <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">รายการอนุมัติเรียบร้อย</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search2" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>วันที่</th>
                  <th>รายการ</th>
                  <th>โปรเจ็ค</th>
                  <th>ประเภท</th>
                  <th>มูลค่า</th>
                  <th>สถานะ</th>
                  <th>อื่นๆ</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">Topic</td>
                  <td class="text-sm font-weight-normal">Project Name</td>
                  <td class="text-sm font-weight-normal">Type</td>
                  <td class="text-sm font-weight-normal">10k</td>
                  <td class="text-sm font-weight-normal">waiting</td>
                 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
        </div>
        <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">รายการอนุมัติเรียบร้อย</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search3" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>วันที่</th>
                  <th>รายการ</th>
                  <th>โปรเจ็ค</th>
                  <th>ประเภท</th>
                  <th>มูลค่า</th>
                  <th>สถานะ</th>
                  <th>อื่นๆ</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">Topic</td>
                  <td class="text-sm font-weight-normal">Project Name</td>
                  <td class="text-sm font-weight-normal">Type</td>
                  <td class="text-sm font-weight-normal">10k</td>
                  <td class="text-sm font-weight-normal">not appoved</td>
                  <td class="text-sm font-weight-normal">edit</td>
                 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
        </div>
        <div class="row">
          <default-statistics-card
            class="col col-lg-3  "
            title="รายการรออนุมัติ"
            count="20 รายการ"
            :percentage="{ color: 'success' }"
           
          />
          <default-statistics-card
            class="col col-lg-3"
            title="รายการอนุมัติ"
            count="10 รายการ"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
            class="col col-lg-3"
            title="รายการไม่อนุมัติ"
            count="5 รายการ"
            :percentage="{ color: 'success'}"
            
          />
        </div>
  </template>
  
  <script>

  import simplepic from "/src/assets/img/bg-smart-home-1.jpg";
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import DefaultStatisticsCard from "/src/views/dashboards/components/DefaultStatisticsCard.vue";
  import { DataTable } from "simple-datatables";
  import MaterialAvatar from "/src/components/MaterialAvatar.vue";
  import user from "/src/assets/icon/user.png";

  export default {
    name: "ProfileOverview",
    components: { DefaultStatisticsCard,MaterialAvatar
    
   
      
    },
    data() {
      return {
        showMenu: false,
        user,
        simplepic,
        imageUrl: require("@/assets/img/products/product-11.jpg"),
    
  
      };
    },
  
    mounted() {
  
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    const dataTableSearch = new DataTable("#datatable-search0", {
      searchable: true,
      fixedHeight: true,
    });
   // eslint-disable-next-line no-unused-vars
   const dataTableSearch1 = new DataTable("#datatable-search1", {
      searchable: true,
      fixedHeight: true,
    });
   // eslint-disable-next-line no-unused-vars
   const dataTableSearch2 = new DataTable("#datatable-search2", {
      searchable: true,
      fixedHeight: true,
    });
    // eslint-disable-next-line no-unused-vars
   const dataTableSearch3 = new DataTable("#datatable-search3", {
      searchable: true,
      fixedHeight: true,
    });
    },
  };
  </script> 
  <style scoped>
  .img {
   max-width: 100%;
   height: auto;
  }

  </style>
  