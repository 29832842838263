<template>
    <div class="py-4 container-fluid">
      <h2>Position</h2>
    
      <div class="mt-6  col-lg-8 mt-lg-0">
          <div class="card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Information</h5>
            <ul class="list-group list-group-flush">
                <li class="list-group-item"><strong>ไอดี:</strong>{{detail.id}}</li>
              <li class="list-group-item"><strong>ชื่อ:</strong>{{detail.name_th}}</li>
              <li class="list-group-item"><strong>Name:</strong> {{detail.name_en}}</li>
              <li class="list-group-item"><strong>รายละเอียด:</strong> ทำไรก็ได้โตแล้ว</li>
            </ul>
            <a :href="`/adminpage/editposition?id=${detail.id}`">
            <material-button
            class="mt-2 mb-3 btn bg-gradient-info ms-lg-auto ">
            edit
            </material-button>
          </a>
          </div>
        </div>
      </div>
    </div>
  
 
</template>
<script>
import MaterialBotton from "/src/components/MaterialButton.vue";
import axios from 'axios';

export default {
  components: {  },
  data() {
    return {MaterialBotton,
        detail:[]
      
      
    };
  },
  mounted() {
    this.fetchDetail()
  },
  methods: {
    async fetchDetail(){

    const result = await axios.get("https://uat-api.susenginetech.com/api/v1/position/"+this.$route.query.id)
      if (result.data.isError == true){
        alert(result.data.errorMessage)
        }
      else{
        this.detail=result.data.data
        console.log(this.detail);
      }
      }
    },
};
</script>
