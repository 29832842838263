<template>
  <h2>Equipment</h2>

  <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <h5 class="mb-0">Equipment List</h5>
            </div>
            <div class="table-responsive">
              <table id="datatable-search0" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Profile</th>
                    <th>Name (TH)</th>
                    <th>Name (EN)</th>
                    <th>Owner</th>
                    <th>Shop</th>
                    <th>Price</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="equipment in equipments" :key="equipment.id">
                    <td class="text-sm font-weight-normal">
                      <material-avatar
                        :img="tools"
                        class="me-1"
                        alt="avatar image"
                      />
                    </td>
                    <td class="text-sm font-weight-normal">
                      <a :href="`/adminpage/equipmentdetail?id=${equipment.id}`">
                        {{ equipment.name_th }}
                      </a>
                    </td>
                    <td class="text-sm font-weight-normal">
                      <a :href="`/adminpage/equipmentdetail?id=${equipment.id}`">
                        {{ equipment.name_en }}
                      </a>
                    </td>
                    <td class="text-sm font-weight-normal">{{ equipment.owner }}</td>
                    <td class="text-sm font-weight-normal"><a href="/adminpage/shopdetail">เอส ซีจี โฮมโซลูชั่น ธัญบุรี</a></td>
                    <td class="text-sm font-weight-normal">{{ equipment.price }}</td>
                    <td class="text-sm font-weight-normal">
                      <span
                        class="badge badge-ms-2"
                        :class="equipment.amount > 0 ? 'badge-success' : 'badge-danger'"
                      >
                        {{ equipment.amount > 0 ? 'In stock' : 'None' }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import simplepic from "/src/assets/img/bg-smart-home-1.jpg";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { DataTable } from "simple-datatables";
import tools from "/src/assets/icon/tools.png";
import MaterialAvatar from "../components/MaterialAvatar.vue";
import axios from "axios";
import { nextTick } from "vue";

export default {
  name: "ProfileOverview",
  components: { MaterialAvatar },
  data() {
    return {
      tools,
      showMenu: false,
      simplepic,
      imageUrl: require("@/assets/img/products/product-11.jpg"),
      equipments: [], // Equipment list fetched from API
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    };
  },
  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    this.equipmentList();
    
  },
  methods: {

  
  async equipmentList() {
    const config = this.getAuthConfig();
    try {
      const response = await axios.get(
        "https://uat-api.susenginetech.com/api/v1/equipment/list",
        config
      );
      if (response.data.isError) {
        this.showError(response.data.errorMessage);
      } else {
        this.equipments = response.data.data;
        await nextTick();
        this.initializeDataTable("#datatable-search0");
      }
    } catch (error) {
      console.error("Error fetching equipment list:", error);
    }
  },

  getAuthConfig() {
    return {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    };
  },

  showError(message) {
    alert(message); // Replace with a better UI notification system
  },

  initializeDataTable(selector) {
    new DataTable(selector, {
      searchable: true,
      fixedHeight: true,
    });
  },
}
}
</script>

<style scoped>
.img {
  max-width: 100%;
  height: auto;
}
</style>
