<template>
    <div class="col-lg-6 mt-3">
    <h2>Position</h2>
    </div>
    <form role="form" class="report-form" @submit.prevent="handleSubmit">
      <div class="card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Add Position</h5>
                <div class="row mt-4 ">
                  <div class="col-12 col-sm-6">
                    <material-input v-model="name_th" value="id"  variant="dynamic" label="ชื่อ" />
                  </div>
                  <div class=" col-sm-6 ">
                    <material-input  v-model="name_en" value="id" variant="dynamic" label="Name" />
                  </div>  
                </div>
                <material-button
                  class="mt-2  bg-gradient-success col-1">Save
                </material-button>
            </div>
          </div>
    </form>
    <form role="form" class="report-form" @submit.prevent="handleSubmitList">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
        <div class="card-header">
          <h5 class="mb-0">Position List</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search0" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>ID</th>
                  <th>ชื่อ</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>        
                <tr v-for="position in positions" :key="position.id">
                  <td class="text-sm font-weight-normal">{{ position.id }}</td>
                  <td class="text-sm font-weight-normal"><a :href="`/adminpage/positiondetail?id=${position.id}`">{{ position.name_th }}</a></td>
                  <td class="text-sm font-weight-normal"><a :href="`/adminpage/positiondetail?id=${position.id}`">{{ position.name_en }}</a></td> 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
  </form>
</template>
<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { DataTable } from "simple-datatables";
import axios from 'axios';
import Swal from 'sweetalert2';
import { nextTick } from "vue";
export default {
    components: { MaterialInput,MaterialButton  },
    data() {
      return {
        positions:[]
      };
    },
mounted() {
    this.handleSubmitList()
    },    
methods:{
  async handleSubmit() {
    // Add form submission logic here
    console.log("Form submitted:", {
      name_th: this.name_th,
      name_en: this.name_en
    });
    const payload = {
      name_th: this.name_th,
      name_en: this.name_en
        }
    const result = await axios.post("https://uat-api.susenginetech.com/api/v1/position/add",payload)
    if(result.data.isError){
            Swal.fire({
              text: result.data.errorMessage,
              icon: "error"
            })
          }else{
            Swal.fire({
              title: "submit successful",
              icon: "success"
            })
            this.name_th="";
            this.name_en="";
            this.$router.push("/adminpage/positionlist");

          }

  },
  async handleSubmitList() {
    const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        };
    try {
    const result = await axios.get("https://uat-api.susenginetech.com/api/v1/position/list",config)
    if(result.data.isError){
        alert(result.data.errorMessage)
      }else{
        this.positions=result.data.data
        await nextTick()
          // eslint-disable-next-line no-unused-vars
  const dataTableSearch = new DataTable("#datatable-search0", {
      searchable: true,
      fixedHeight: true,
    });
      }
    console.log("this.position",this.positions);
    } catch (error) {
      console.log(error);
    }
  }
}
}
</script>