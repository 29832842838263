<template>
    <navbar btn-background="bg-gradient-success" />
    <div
      class="page-header align-items-start min-vh-100"
      style="
        background-image: url(&quot;https://images.unsplash.com/photo-1520769945061-0a448c463865?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80;);
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container my-auto">
        <div class="row">
          <div class="col-lg-4 col-md-8 col-12 mx-auto">
            <div class="card z-index-0 fadeIn3 fadeInBottom">
              <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div
                  class="bg-gradient-info shadow-info border-radius-lg py-3 pe-1"
                >
                  <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                    Sign in
                  </h4>
                <div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <form role="form" class="text-start mt-3" @submit.prevent="login" >
                  <div class="mb-3">
                    <material-input
                      v-model="email"
                      id="email"
                      :value="email"
                      type="email"
                      label="Email"
                      name="email"
                    />
                  </div>
                  <div class="mb-3">
                    <material-input
                      v-model="password"
                      id="password"
                      type="password"
                      label="Password"
                      name="password"
                    />
                  </div>
                  <material-switch id="rememberMe" name="Remember Me"
                    >Remember me</material-switch
                  >
                  <p><a href="/forgetpassword">Forgot your password?</a></p>
               
                    <material-button
                      class="my-4 mb-2"
                      variant="gradient"
                      color="info"
                      full-width
                      >Sign in</material-button
                    >
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer position-absolute bottom-2 py-2 w-100">
        <div class="container">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-12 col-md-6 my-auto">
              <div class="copyright text-center text-sm text-white text-lg-start">
                © {{ new Date().getFullYear() }}, made with
                <i class="fa fa-heart" aria-hidden="true"></i> by
                <a
                  href="#"
                  class="font-weight-bold text-white"
                  target="_blank"
                  >Suset Co.,Ltd</a
                >
                for a better web.
              </div>
            </div>
            <div class="col-12 col-md-6">
              <ul
                class="nav nav-footer justify-content-center justify-content-lg-end"
              >
                <li class="nav-item">
                  <a
                    href="/projectpage"
                    class="nav-link text-white"
                    target="_blank"
                    >Project</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    href="/projectpage"
                    class="nav-link text-white"
                    target="_blank"
                    >Co-Worker</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    href="/member"
                    class="nav-link text-white"
                    target="_blank"
                    >Member</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    href="/Report"
                    class="nav-link pe-0 text-white"
                    target="_blank"
                    >Report</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </template>
  
  <script>
  import Navbar from "@/examples/PageLayout/Navbar.vue";
  import MaterialInput from "@/components/MaterialInput.vue";
  import MaterialSwitch from "@/components/MaterialSwitch.vue";
  import MaterialButton from "@/components/MaterialButton.vue";
  import { mapMutations } from "vuex";
  import axios from "axios";
  import Swal from 'sweetalert2';
  import { jwtDecode } from "jwt-decode";

  export default {
    name: "SigninBasic",
    components: {
      Navbar,
      MaterialInput,
      MaterialSwitch,
      MaterialButton,
    },
    data() {
      return {
        
      };
    },
    beforeMount() {
      this.toggleEveryDisplay();
      this.toggleHideConfig();
    },
    beforeUnmount() {
      this.toggleEveryDisplay();
      this.toggleHideConfig();
    },
    methods: {
      ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
      updateEmail(event) {
      this.email = event.target.value;
      },
      updatePassword(event) {
        this.password = event.target.value;
      },
      async login() {
      try {
          const response = await axios.post("https://uat-api.susenginetech.com/api/v1/auth/login", {
            email: this.email,
            password: this.password,
          });
          if(response.data.isError){
            Swal.fire({
              text: response.data.errorMessage,
              icon: "error"
            })
          }else{
            Swal.fire({
              title: "Login successful",
              icon: "success"
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.setItem('authToken',response.data.data.token)
                const decoded = jwtDecode(response.data.data.token);
                console.log(decoded);
                localStorage.setItem('userId',decoded.user.id)
                console.log(decoded.user.id);
                

  
                this.$router.push("/adminpage/dashboard");
              }
            });
          }
      } catch (error) {
        Swal.fire({
            text: error.message,
            icon: "error"
          })
      }
    },
    },
  };
  </script>