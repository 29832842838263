<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="brooklynAlice"
          :nav-text="profile.staff.first_name_en + ' ' + profile.staff.last_name_en "
          :class="getRoute() === 'brooklynAlice' ? 'active' : ''"
        >
          <template #icon>
            <img src="/src/assets/icon/user.png" class="avatar" />
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'profilepage' }"
                mini-icon="P"
                text="My Profile"
              />
              <sidenav-item
                :to="{ name: 'editprofile' }"
                  mini-icon="E"
                  text="Edit Profile"
              />
              <sidenav-item
                :to="{ name: '/' }"
                  mini-icon="L"
                  text="Log Out"
                  @click="logout"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <hr class="horizontal light mt-2 mb-2" />
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="dashboardsExamples"
          nav-text="Dashboards"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">dashboard</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                      :to="{ name: 'dashboard' }"
                      mini-icon="D"
                      text="Dashboards"
                    />
                   
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="getRoute() === 'member' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="memberExamples"
          nav-text="Member"
          :class="getRoute() === 'member' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">person</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                      :to="{ name: 'memberlist' }"
                      mini-icon="M"
                      text="Member"
                    />
                    <sidenav-item
                      :to="{ name: 'registermember' }"
                      mini-icon="R"
                      text="Register"
                    />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="getRoute() === 'finance' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="financeExamples"
          nav-text="Finance"
          :class="getRoute() === 'finance' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">money</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
                    <sidenav-item
                      :to="{ name: 'FinanceSalary' }"
                      mini-icon="S"
                      text="Salary"
                    />
                    <sidenav-item
                      :to="{ name: 'FinanceOT' }"
                      mini-icon="O"
                      text="OT"
                    />
                    <sidenav-item
                      :to="{ name: 'FinanceBonus' }"
                      mini-icon="B"
                      text="Bonus"
                    />
                    <sidenav-item
                      :to="{ name: 'financereceipts' }"
                      mini-icon="R"
                      text="Receipts"
                    />
                    <sidenav-item
                      :to="{ name: 'financeexpenses' }"
                      mini-icon="E"
                      text="Expense"
                    />
                    <sidenav-item
                      :to="{ name: 'financepurches' }"
                      mini-icon="p"
                      text="Purchase"
                    />
                    <sidenav-item
                      :to="{ name: 'document' }"
                      mini-icon="D"
                      text="Document"
                    />
                  </ul>
                  </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="getRoute() === 'project' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="projectExamples"
          nav-text="Project"
          :class="getRoute() === 'project' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">content_paste</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                      :to="{ name: 'project' }"
                      mini-icon="A"
                      text="All Project"
                    />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" :class="getRoute() === 'billing' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="billingExamples"
          nav-text="Billing"
          :class="getRoute() === 'billing' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">receipt_long</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                      :to="{ name: 'QObiling' }"
                      mini-icon="Q"
                      text="QO Billing"
                    />
                    <sidenav-item
                      :to="{ name: 'billingnote' }"
                      mini-icon="B"
                      text="Billing Note"
                    />
                    <sidenav-item
                      :to="{ name: 'QObiling' }"
                      mini-icon="T"
                      text="Tax Invoice"
                    />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="getRoute() === 'appovve' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="appovveExamples"
          nav-text="Appove"
          :class="getRoute() === 'appovve' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">apps</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                      :to="{ name: 'appovepersonal' }"
                      mini-icon="P"
                      text="Personal"
                    />
                    <sidenav-item
                      :to="{ name: 'appoveproject' }"
                      mini-icon="P"
                      text="Project"
                    />
                    <sidenav-item
                      :to="{ name: 'appovesalary' }"
                      mini-icon="S"
                      text="Salary"
                    />
                    <sidenav-item
                      :to="{ name: 'appoveOT' }"
                      mini-icon="O"
                      text="OT"
                    />
                    <sidenav-item
                      :to="{ name: 'appoveBonus' }"
                      mini-icon="B"
                      text="Bonus"
                    />
                    <sidenav-item
                      :to="{ name: 'appovepurches' }"
                      mini-icon="P"
                      text="Perchase"
                    />
                    <sidenav-item
                      :to="{ name: 'appovefinance' }"
                      mini-icon="ER"
                      text="Expanses-Receipt"
                    />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="getRoute() === 'eqiupment' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="eqiupmentExamples"
          nav-text="Equipment"
          :class="getRoute() === 'eqiupment' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">upcoming</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                      :to="{ name: 'eqiupment' }"
                      mini-icon="E"
                      text="Equipment"
                    />
                    <sidenav-item
                      :to="{ name: 'addequipment' }"
                      mini-icon="A"
                      text="Add Equipment"
                    />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="getRoute() === 'material' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="materialExamples"
          nav-text="Material"
          :class="getRoute() === 'material' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">view_in_ar</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                      :to="{ name: 'material' }"
                      mini-icon="M"
                      text="Material"
                    />
                    <sidenav-item
                      :to="{ name: 'addmaterial' }"
                      mini-icon="A"
                      text="Add Material"
                    />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="getRoute() === 'shoppage' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="shoppageExamples"
          nav-text="Shop"
          :class="getRoute() === 'shoppage' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">shopping_basket</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                      :to="{ name: 'shoppage' }"
                      mini-icon="S"
                      text="Shop"
                    />
                    <sidenav-item
                      :to="{ name: 'registershop' }"
                      mini-icon="A"
                      text="Add Shop"
                    />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="getRoute() === 'coworker' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="coworkerExamples"
          nav-text="Co-Worker"
          :class="getRoute() === 'coworker' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">image</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                      :to="{ name: 'coworker' }"
                      mini-icon="C"
                      text="Co-Worker"
                    />
                    <sidenav-item
                      :to="{ name: 'addcoworker' }"
                      mini-icon="A"
                      text="Add Co-Worker"
                    />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="getRoute() === 'setting' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="settingExamples"
          nav-text="Setting"
          :class="getRoute() === 'setting' ? 'active' : ''"
          :to="{ name: 'setting' }" 
        >
          <template #icon>
            <i class="material-icons-round opacity-10">dashboard</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'settingpage' }" mini-icon="S" text="Setting" />
              <sidenav-item :to="{ name: 'addrole' }" mini-icon="R" text="Role" />
              <sidenav-item :to="{ name: 'reportlist' }" mini-icon="Re" text="Report" />
              <sidenav-item :to="{ name: 'positionlist' }" mini-icon="P" text="Position" />
            </ul>
          </template>
          
        </sidenav-collapse>
      </li>
          
            </ul>

  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import { mapState } from "vuex";
import axios from "axios";
import memberIcon from '@/assets/icon/member.png';
export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,

  },
  data() {
    return {memberIcon,
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      fullname:"abc",
      profile: {
        staff : {
          first_name_en :"",
          
}
}
    };
  },
  computed: {
    ...mapState(["isRTL"])
  },
  mounted() {
    this.fetchProfile();
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    async fetchProfile(){
      if(localStorage.getItem('authToken') == null || localStorage.getItem('userId') == null)
        return;

      const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        };
    const result = await axios.get("https://uat-api.susenginetech.com/api/v1/profile/view/"+localStorage.getItem('userId'),config)
    console.log(result);
    
      if (result.data.isError == true){
        alert(result.data.errorMessage)
        }
      else{
        this.profile=result.data.data
        console.log(this.profile);
      }
      },
      logout(){
        localStorage.removeItem('authToken')
        localStorage.removeItem('userId')
        this.$router.push("loginpage")
      }
  }
};
</script>
