<template>
  <h3 class="mt-3 ">Project </h3>
  <div class="py-4 container-fluid">
  <div class="card-header">
    <div class="mt-4 row">
    <div class="col-12">
      <div class="card">
        <!-- Card header -->
        <div class="card-header">
          <h5 class="mb-0">Project List</h5>
        </div>
        <div class="table-responsive">
          <table id="datatable-search0" class="table table-flush">
            <thead class="thead-light">
              <tr>
                <th>Date</th>
                <th>Project</th>
                <th>Create by</th>
                <th>More</th>

                
              </tr>
            </thead>
            <tbody>
              
              <tr>
                <td class="text-sm font-weight-normal">2011/04/25</td>
                <td class="text-sm font-weight-normal">Topic</td>
                <td class="text-sm font-weight-normal">เปมิศา</td>
                <td class="text-sm font-weight-normal">more</td>                  
              </tr>
              <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                <td class="text-sm font-weight-normal">Topic</td>
                <td class="text-sm font-weight-normal">เปมิศา</td>
                <td class="text-sm font-weight-normal">more</td>          
                
              </tr>
              <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                <td class="text-sm font-weight-normal">Topic</td>
                <td class="text-sm font-weight-normal">เปมิศา</td>
                <td class="text-sm font-weight-normal">more</td>          
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
  </div>
      </div>
</template>
<script>
import { DataTable } from "simple-datatables";

export default {
mounted() {
  // eslint-disable-next-line no-unused-vars
  const dataTableSearch = new DataTable("#datatable-search0", {
    searchable: true,
    fixedHeight: true,
  });
},
};
</script>

