<template>
  <h2>Shop</h2>

      <div class="py-4 container-fluid">
  <div class="card-header">
    <div class="mt-4 row">
    <div class="col-12">
      <div class="card">
        <!-- Card header -->
        <div class="card-header">
          <h5 class="mb-0">Shop List</h5>
        </div>
        <div class="table-responsive">
          <table id="datatable-search0" class="table table-flush">
            <thead class="thead-light">
              <tr>
                <th>โปรไฟล์</th>
                <th>ชื่อ</th>
                <th>จังหวัด</th>
                <th>อำเภอ</th>
                <th>ตำบล</th>
                <th>สถานะ</th>
                
              </tr>
            </thead>
            <tbody>
              
              <tr>
                  <td  class="text-sm font-weight-normal ">
                <material-avatar
                  :img="shop"
                  class="me-1"
                  alt="avatar image"
                />
                  </td>
                <td class="text-sm font-weight-normal"><a href="/adminpage/shopdetail">เอส ซีจี โฮมโซลูชั่น ธัญบุรี</a></td> 
                <td class="text-sm font-weight-normal">ปทุมธานี</td>
                <td class="text-sm font-weight-normal">ธัญบุรี</td>
                <td class="text-sm font-weight-normal">บึงน้ำรักษ์</td>
                <td class="text-sm font-weight-normal"><span class="badge badge-warning">Tax</span>
                  <span class="mb-3 ms-2 badge badge-info">Open</span></td>
                
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
  </div>
      </div>
      
</template>

<script>

import simplepic from "/src/assets/img/bg-smart-home-1.jpg";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { DataTable } from "simple-datatables";
import shop from "/src/assets/icon/shop.png";
import MaterialAvatar from "../components/MaterialAvatar.vue";
export default {
  components: { MaterialAvatar
  },  
  data() {
    return {shop,
      showMenu: false,
      simplepic,
      imageUrl: require("@/assets/img/products/product-11.jpg"),
      


    };
  },

  mounted() {

    setNavPills();
    setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
  const dataTableSearch = new DataTable("#datatable-search0", {
      searchable: true,
      fixedHeight: true,
    });
  
 
  },
  
};
</script> 
<style scoped>
.img {
 max-width: 100%;
 height: auto;
}

</style>
