<template>
  <h2>Material</h2>

  <div class="py-4 container-fluid">
  <div class="card-header">
    <div class="mt-4 row">
    <div class="col-12">
      <div class="card">
        <!-- Card header -->
        <div class="card-header">
          <h5 class="mb-0">Material List</h5>
        </div>
        <div class="table-responsive">
          <table id="datatable-search0" class="table table-flush">
            <thead class="thead-light">
              <tr>
                <th>Profile</th>
                <th>Name</th>
                <th>Name</th>
                <th>Shop</th>
                <th>Price</th>
                <th>Status</th>
                <th>Other</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="material in materials" :key="material.id">
                  <td  class="text-sm font-weight-normal ">
                <material-avatar
                  :img="mat"
                  class="me-1"
                  alt="avatar image"
                /> 
                  </td>
                <td class="text-sm font-weight-normal"><a :href="`/adminpage/materialdetail?id=${material.id}`">{{material.name_th}}</a></td>
                <td class="text-sm font-weight-normal"><a :href="`/adminpage/materialdetail?id=${material.id}`">{{material.name_en}}</a></td>
                <td class="text-sm font-weight-normal"><a href="/adminpage/shopdetail">เอส ซีจี โฮมโซลูชั่น ธัญบุรี</a></td>
                <td class="text-sm font-weight-normal">{{material.price}}</td>
                <td class="text-sm font-weight-normal">
                      <span
                        class="badge badge-ms-2"
                        :class="material.amount > 0 ? 'badge-success' : 'badge-danger'"
                      >
                        {{ material.amount > 0 ? 'In stock' : 'None' }}
                      </span>
                    </td>
              
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
      
</template>

<script>

import simplepic from "/src/assets/img/bg-smart-home-1.jpg";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { DataTable } from "simple-datatables";
import mat from "/src/assets/icon/mat.png";
import MaterialAvatar from "../components/MaterialAvatar.vue";
import axios from 'axios';
import { nextTick } from "vue";
export default {
  name: "ProfileOverview",
  components: { MaterialAvatar
  
 
    
  },
  data() {
    return {mat,
      showMenu: false,
      simplepic,
      imageUrl: require("@/assets/img/products/product-11.jpg"),
      materials:[]


    };
  },

  mounted() {

    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    
  
  this.materialList()
  },
  methods:{
  async materialList(){
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      };
  try {
    const response = await axios.get("https://uat-api.susenginetech.com/api/v1/material/list",config)
    if(response.data.isError){
      alert(response.data.errorMessage)
    }else{
      this.materials=response.data.data
      await nextTick()
     // eslint-disable-next-line no-unused-vars
  const dataTableSearch = new DataTable("#datatable-search0", {
      searchable: true,
      fixedHeight: true,
    });
  
  console.log("this.material",this.materials);
  
    }
  } catch (error) {
    console.log(error);
  }
}
}
};
</script> 
<style scoped>
.img {
 max-width: 100%;
 height: auto;
}

</style>
