<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <default-statistics-card
          title="Sum of Receipts"
          count="$230,220"
          :percentage="{ color: 'success', value: '+55%', label: 'since last month' }"
          menu="6 May - 7 May"
          :dropdown="dropdownOptions"
        />
        <default-statistics-card
          title="Project"
          count="3,200"
          :percentage="{ color: 'success', value: '+12%', label: 'since last month' }"
          menu="9 June - 12 June"
          :dropdown="dropdownOptions"
        />
        <default-statistics-card
          title="Staff"
          count="$1,200"
          :percentage="{ color: 'secondary', value: '+$213', label: 'since last month' }"
          menu="6 August - 9 August"
          :dropdown="dropdownOptions"
        />
      </div>
      <div class="row mt-3">
        <div class="col-lg-8 col-sm-6 mt-sm-0">
          <revenue-chart-card />
        </div>
        <div class="col-lg-4 mt-lg-0">
          <div class="card">
            <div class="p-3 pb-0 card-header">
              <div class="d-flex justify-content-between">
                <h6 class="mb-0">Sum of Project</h6>
              </div>
            </div>
            <div class="p-3 card-body">
              <ul class="list-group list-group-flush list my--3">
                <li
                  v-for="({ country, sales, percentage }, index) in salesArray"
                  :key="index"
                  class="px-0 border-0 list-group-item"
                >
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <!-- Uncomment and add img tag here if needed -->
                    </div>
                    <div class="col">
                      <p class="mb-0 text-xs font-weight-bold">Name:</p>
                      <h6 class="mb-0 text-sm">{{ country }}</h6>
                    </div>
                    <div class="text-center col">
                      <p class="mb-0 text-xs font-weight-bold">Receipts:</p>
                      <h6 class="mb-0 text-sm">{{ sales }}</h6>
                    </div>
                    <div class="text-center col">
                      <p class="mb-0 text-xs font-weight-bold">Expense:</p>
                      <h6 class="mb-0 text-sm">{{ percentage }}</h6>
                    </div>
                  </div>
                  <hr class="mt-3 mb-1 horizontal dark" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Project</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Budget</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Status</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Completion</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="d-flex px-2">
                <div>
                  <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/logos/small-logos/logo-spotify.svg" class="avatar avatar-sm rounded-circle me-2">
                </div>
                <div class="my-auto">
                  <h6 class="mb-0 text-xs">Spotify</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">$2,500</p>
            </td>
            <td>
              <span class="badge badge-dot me-4">
                <i class="bg-info"></i>
                <span class="text-dark text-xs">working</span>
              </span>
            </td>
            <td class="align-middle text-center">
              <div class="d-flex align-items-center">
                <span class="me-2 text-xs">60%</span>
                <div>
                  <div class="progress">
                    <div class="progress-bar bg-info" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style="width: 60%;"></div>
                  </div>
                </div>
              </div>
            </td>

            <td class="align-middle">
              <button class="btn btn-link text-secondary mb-0">
                <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
              </button>
            </td>
          </tr>

          <tr>
            <td>
              <div class="d-flex px-2">
                <div>
                  <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/logos/small-logos/logo-invision.svg" class="avatar avatar-sm rounded-circle me-2">
                </div>
                <div class="my-auto">
                  <h6 class="mb-0 text-xs">Invision</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">$5,000</p>
            </td>
            <td>
              <span class="badge badge-dot me-4">
                <i class="bg-success"></i>
                <span class="text-dark text-xs">done</span>
              </span>
            </td>
            <td class="align-middle text-center">
              <div class="d-flex align-items-center">
                <span class="me-2 text-xs">100%</span>
                <div>
                  <div class="progress">
                    <div class="progress-bar bg-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
                  </div>
                </div>
              </div>
            </td>

            <td class="align-middle">
              <button class="btn btn-link text-secondary mb-0" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
              </button>
            </td>
          </tr>

          <tr>
            <td>
              <div class="d-flex px-2">
                <div>
                  <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/logos/small-logos/logo-jira.svg" class="avatar avatar-sm rounded-circle me-2">
                </div>
                <div class="my-auto">
                  <h6 class="mb-0 text-xs">Jira</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">$3,400</p>
            </td>
            <td>
              <span class="badge badge-dot me-4">
                <i class="bg-danger"></i>
                <span class="text-dark text-xs">canceled</span>
              </span>
            </td>
            <td class="align-middle text-center">
              <div class="d-flex align-items-center">
                <span class="me-2 text-xs">30%</span>
                <div>
                  <div class="progress">
                    <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="30" style="width: 30%;"></div>
                  </div>
                </div>
              </div>
            </td>

            <td class="align-middle">
              <button class="btn btn-link text-secondary mb-0" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
              </button>
            </td>
          </tr>

          <tr>
            <td>
              <div class="d-flex px-2">
                <div>
                  <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/logos/small-logos/logo-slack.svg" class="avatar avatar-sm rounded-circle me-2">
                </div>
                <div class="my-auto">
                  <h6 class="mb-0 text-xs">Slack</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">$1,000</p>
            </td>
            <td>
              <span class="badge badge-dot me-4">
                <i class="bg-danger"></i>
                <span class="text-dark text-xs">canceled</span>
              </span>
            </td>
            <td class="align-middle text-center">
              <div class="d-flex align-items-center">
                <span class="me-2 text-xs">0%</span>
                <div>
                  <div class="progress">
                    <div class="progress-bar bg-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="0" style="width: 0%;"></div>
                  </div>
                </div>
              </div>
            </td>

            <td class="align-middle">
              <button class="btn btn-link text-secondary mb-0" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
              </button>
            </td>
          </tr>

          <tr>
            <td>
              <div class="d-flex px-2">
                <div>
                  <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/logos/small-logos/logo-webdev.svg" class="avatar avatar-sm rounded-circle me-2">
                </div>
                <div class="my-auto">
                  <h6 class="mb-0 text-xs">Webdev</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">$14,000</p>
            </td>
            <td>
              <span class="badge badge-dot me-4">
                <i class="bg-info"></i>
                <span class="text-dark text-xs">working</span>
              </span>
            </td>
            <td class="align-middle text-center">
              <div class="d-flex align-items-center">
                <span class="me-2 text-xs">80%</span>
                <div>
                  <div class="progress">
                    <div class="progress-bar bg-info" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="80" style="width: 80%;"></div>
                  </div>
                </div>
              </div>
            </td>

            <td class="align-middle">
              <button class="btn btn-link text-secondary mb-0" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
              </button>
            </td>
          </tr>

          <tr>
            <td>
              <div class="d-flex px-2">
                <div>
                  <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/logos/small-logos/logo-xd.svg" class="avatar avatar-sm rounded-circle me-2">
                </div>
                <div class="my-auto">
                  <h6 class="mb-0 text-xs">Adobe XD</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">$2,300</p>
            </td>
            <td>
              <span class="badge badge-dot me-4">
                <i class="bg-success"></i>
                <span class="text-dark text-xs">done</span>
              </span>
            </td>
            <td class="align-middle text-center">
              <div class="d-flex align-items-center">
                <span class="me-2 text-xs">100%</span>
                <div>
                  <div class="progress">
                    <div class="progress-bar bg-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
                  </div>
                </div>
              </div>
            </td>

            <td class="align-middle">
              <button class="btn btn-link text-secondary mb-0" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
              </button>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
      <div class="mt-4 row">
        <div class="col-12">
          <orders-list-card
            title="Task"
            :headers="['Task', 'Project', 'Date', 'status']"
            :lists="products"
          />
        </div>
      </div>
    </div>
  </template>
<script>
import DefaultStatisticsCard from "/src/views/dashboards/components/DefaultStatisticsCard.vue";
import RevenueChartCard from "/src/views/dashboards/components/RevenueChartCard.vue";
import OrdersListCard from "/src/examples/Cards/OrdersListCard.vue";
// import axios from "axios";
export default {
  name: "Sales",
  components: {
    DefaultStatisticsCard,
    RevenueChartCard,
    OrdersListCard,
  },
  data() {
    return {
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          percentage: "29.9%",
        },
        germany: {
          country: "Germany",
          sales: 3900,
          percentage: "40.22%",
        },
        britain: {
          country: "Great Britain",
          sales: 1400,
          percentage: "23.44%",
        },
        brasil: {
          country: "Brazil",
          sales: 562,
          percentage: "32.14%",
        },
        australia: {
          country: "Australia",
          sales: 400,
          percentage: "56.83%",
        },
      },
      products: [
        {
          title: "Nike v22 Running",
          order: "12.821",
          values: ["$130.992", "$9.500", "13"],
          info: "Refund rate is lower with 97% than other products",
          image: "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/blue-shoe.jpg",
          icon: "bold-down text-success",
        },
        {
          title: "Business Kit (Mug + Notebook)",
          order: "8.232",
          values: ["$80.250", "$4.200", "40"],
          image: "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/black-mug.jpg",
          icon: "bold-down text-success",
        },
        {
          title: "Black Chair",
          order: "2.421",
          values: ["$40.600", "$9.430", "54"],
          image: "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/black-chair.jpg",
          icon: "bold-up text-danger",
        },
        {
          title: "Wireless Charger",
          order: "5.921",
          values: ["$91.300", "$7.364", "5"],
          image: "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/bang-sound.jpg",
          icon: "bold-down text-success",
        },
        {
          title: "Mountain Trip Kit (Camera + Backpack)",
          order: "921",
          values: ["$140.925", "$20.531", "121"],
          info: "Refund rate is higher with 70% than other products",
          image: "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/photo-tools.jpg",
          icon: "bold-up text-danger",
        },
      ],
      dropdownOptions: [
        {
          label: "Last 7 days",
          route: "javascript:;",
        },
        {
          label: "Last week",
          route: "javascript:;",
        },
        {
          label: "Last 30 days",
          route: "javascript:;",
        },
      ],
    };
  },
  computed: {
    salesArray() {
      return Object.values(this.sales);
    },
  },
  // mounted() {
  //   this.fetchProfile();
  // },

  // methods: {
  //   async fetchProfile(){
  //     const config = {
  //         headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
  //       };
  //   const result = await axios.get("https://uat-api.susenginetech.com/api/v1/profile/view/"+localStorage.getItem('userId'),config)
  //     if (result.data.isError == true){
  //       alert(result.data.errorMessage)
  //       }
  //     else{
  //       this.profile=result.data.data
  //       console.log(this.profile);
  //     }
  //     }
  //   },
};
</script>
  