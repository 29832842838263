
<template>
      <div class="container-fluid">
        <div
          class="page-header  border-radius-xl mt-7"
        >
        </div>
        <div class="card card-body mx-3 mx-md-4 mt-n6">
          <div>
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <img
                  src="src\assets\icon\SUSET.png"
                  alt="profile_image"
                  class="shadow-sm w-100 border-radius-lg"
                />
              </div>
            </div>
          
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{profile.staff.first_name_en}} {{profile.staff.last_name_en}}</h5>
                <p class="mb-0 font-weight-normal text-sm">{{profile.staff.position_id.name_en}}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mt-2 row">
              <div class="col-12 position-relative">
                <p></p>                
                <ul class="information">
                  <li><strong>First Name : </strong> {{profile.staff.first_name_en}} </li>
                  <li><strong>Last Name : </strong>{{profile.staff.last_name_en}}  </li>
                  <li><strong>Nickname : </strong>{{profile.staff.nickname_en}} </li>
                  <li><strong>Identity card no : </strong>  {{profile.staff.c_id}}</li>
                  <li><strong>Start work : </strong> {{profile.staff.start_work_date}} </li>
                  <li><strong>Role : </strong> {{profile.staff.role_id.name_en}} </li>
                  <li><strong>Position in company : </strong> {{profile.staff.position_id.name_en}} </li>
                  <li><strong>Level : </strong>  </li>
                  <li><strong>Phone Number : </strong> {{profile.staff.tel}} </li>
                  <li><strong>Address : </strong>{{profile.staff.contact}} </li>
                  <li><strong>Email : </strong>  {{profile.staff.email}} </li>
                </ul>
                <a href="/adminpage/editprofile"><material-button  class="bt-edit bg-gradient-success"><i class="material-icons text-white position-relative text-md pe-2">edit</i>edit</material-button></a>
              </div>
              
      <div class="row mt-4">
              <div class="col-12">
                <div class="mb-5 ps-3">
                  <h6 class="mb-1">Projects</h6>
                  <p class="text-sm">Related projects:</p>
                </div>
                <div class="row">
                  <default-project-card
                    title="Modern"
                    :image="img1"
                    label="Project #2"
                    description="As Uber works through a huge amount of internal
                    management turmoil."
                    :authors="[
                      {
                        image: team1,
                        name: 'Elena Morison',
                      },
                      {
                        image: team2,
                        name: 'Ryan Milly',
                      },
                      {
                        image: team3,
                        name: 'Nick Daniel',
                      },
                      {
                        image: team4,
                        name: 'Peterson',
                      },
                    ]"
                    :action="{
                      color: 'success',
                      label: 'View Project',
                    }"
                  />
    
                  <default-project-card
                    title="Scandinavian"
                    :image="img2"
                    label="Project #1"
                    description="Music is something that every person has his or her own
                          specific opinion about."
                    :authors="[
                      {
                        image: team3,
                        name: 'Nick Daniel',
                      },
                      {
                        image: team4,
                        name: 'Peterson',
                      },
                      {
                        image: team1,
                        name: 'Elena Morison',
                      },
                      {
                        image: team2,
                        name: 'Ryan Milly',
                      },
                    ]"
                    :action="{
                      color: 'success',
                      label: 'View Project',
                    }"
                  />
                  <default-project-card
                    title="Minimalist"
                    :image="img3"
                    label="Project #3"
                    description="Different people have different taste, and various types
                          of music."
                    :authors="[
                      {
                        image: team4,
                        name: 'Peterson',
                      },
                      {
                        image: team3,
                        name: 'Nick Daniel',
                      },
                      {
                        image: team1,
                        name: 'Elena Morison',
                      },
                      {
                        image: team2,
                        name: 'Ryan Milly',
                      },
                    ]"
                    :action="{
                      color: 'success',
                      label: 'View Project',
                    }"
                  />
                  <default-project-card
                    title="Gothic"
                    image="https://images.unsplash.com/photo-1606744824163-985d376605aa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
                    label="Project #4"
                    description="Why would anyone pick blue over pink? Pink is obviously a
                          better color."
                    :authors="[
                      {
                        image: team4,
                        name: 'Peterson',
                      },
                      {
                        image: team3,
                        name: 'Nick Daniel',
                      },
                      {
                        image: team2,
                        name: 'Ryan Milly',
                      },
                      {
                        image: team1,
                        name: 'Elena Morison',
                      },
                    ]"
                    :action="{
                      color: 'success',
                      label: 'View Project',
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      </div>
    </template>
    
    <script>
    import DefaultProjectCard from "/src/views/pages/profile/components/DefaultProjectCard.vue";
    import sophie from "@/assets/img/kal-visuals-square.jpg";
    import marie from "@/assets/img/marie.jpg";
    import ivana from "@/assets/img/ivana-square.jpg";
    import peterson from "@/assets/img/team-4.jpg";
    import nick from "@/assets/img/team-3.jpg";
    import img1 from "@/assets/img/home-decor-1.jpg";
    import img2 from "@/assets/img/home-decor-2.jpg";
    import img3 from "@/assets/img/home-decor-3.jpg";
    import team1 from "/src/assets/img/team-1.jpg";
    import team2 from "/src/assets/img/team-2.jpg";
    import team3 from "/src/assets//img/team-3.jpg";
    import team4 from "/src/assets/img/team-4.jpg";
    import MaterialButton from "/src/components/MaterialButton.vue";
    import axios from 'axios';
    import setNavPills from "@/assets/js/nav-pills.js";
    import setTooltip from "@/assets/js/tooltip.js";
    export default {
      name: "ProfileOverview",
      components: {
        DefaultProjectCard,MaterialButton
      },
      data() {
        return {
          showMenu: false,
          sophie,
          marie,
          ivana,
          peterson,
          nick,
          img1,
          team1,
          team2,
          team3,
          team4,
          img2,
          img3,
          profile:{
          create_date: "",
          create_by: null,
          update_date: "",
          update_by: null,
          id: "",
          email: "",
          password: "",
          status: true,
          staff: {
            create_date: "",
            create_by: null,
            update_date: "",
            update_by: null,
            id: "",
            staff_id: "",
            c_id: "",
            account_id: "",
            title_id: {
              create_date: "",
              create_by: null,
              update_date: "",
              update_by: null,
              id: '',
              name_th: "",
              name_en: "",
            },
            first_name_th: "",
            last_name_th: "",
            first_name_en: "",
            last_name_en: "",
            nickname_th: "",
            nickname_en: "",
            start_work_date: "",
            end_of_work_date: "",
            tel: "",
            email: "",
            line_id: "",
            contact: "",
            status_id: {
              create_date: "",
              create_by: null,
              update_date: "",
              update_by: null,
              id: 1,
              name_th: "",
              name_en: "",
            },
            department_id: {
              create_date: "",
              create_by: null,
              update_date: "",
              update_by: null,
              id: 1,
              name_th: "",
              name_en: "",
            },
            position_id: {
              create_date: "",
              create_by: null,
              update_date: "",
              update_by: null,
              id: 1,
              name_th: "",
              name_en: "",
            },
            role_id: {
              create_date: "",
              create_by: null,
              update_date: "",
              update_by: null,
              id: '',
              name_th: "",
              name_en: "",
            }
  }
}
        };
      },
    
      mounted() {
    
        setNavPills();
        setTooltip(this.$store.state.bootstrap);
        this.fetchProfile()
      },
        beforeUnmount() {
          this.$store.state.isAbsolute = false;
      
        },
        methods: {
    async fetchProfile(){
      const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        };
        const result = await axios.get("https://uat-api.susenginetech.com/api/v1/profile/view/"+localStorage.getItem('userId'),config)
        if (result.data.isError == true){
        alert(result.data.errorMessage)
        }
      else{
        this.profile=result.data.data
        console.log(this.profile);
      }
      }
    },
       
    };
    </script> 
    <style scoped>
    .col-12 col-md-6 col-xl-4 mt-md-0 mt-4  {
      text-align: left;
      margin-left: 10;
    }
    .information{
      font-size: 14px;
      color: #555454;
      margin: 30px 0;
      line-height: 30px;
    }
    </style>
    