<template>
    <div class="py-4 container-fluid">
        <div class="col-lg-6 mt-3">
          <h4>Add Equipment</h4>
      </div>
      <div class="mt-4 row">
        <div class="col-lg-4">
        <div class="card mt-4" data-animation="true">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
          <div
              id="productImg"
              action="/file-upload"
              class="form-control border dropzone"
            ></div>
          
          </div>
        </div>
      </div>
        <div class="mt-4 col-lg-8 mt-lg-0">
          <form role="form" class="report-form" @submit.prevent="handleSubmit">
          <div class="card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Equipment Information</h5>
              <div class="row mt-4">
                <div class="col-12 col-sm-6 mt-4">
                  <material-input v-model="name_th" variant="dynamic" label="ชื่อ" />
                </div>
                <div class="col-12 col-sm-6 mt-4">
                  <material-input v-model="name_en" variant="dynamic" label="Name" />
                </div>
                <div class="col-12 col-sm-6 mt-4">
                  <material-input v-model="serial" variant="dynamic" label="Serial Number" />
                </div>
                <div class="col-12 col-sm-6 mt-4">
                  <material-input  v-model="DateBuy" variant="dynamic" type="date"  />
                </div>
                <div class="col-12 col-sm-6 mt-4">
                  <material-input  variant="dynamic" label="price" type="number"/>
                </div>
                <div class="col-12 col-sm-6 mt-4">
                  <material-input v-model="Amount" variant="dynamic" label="Amount" />
                </div>
               
                <div class="col-sm-12">
                <label class="mt-2 ms-0">Type</label>
                  <select
                  id="choices-type-edit"
                  v-model="choices_type"
                    class="form-control"
                    name="choices-type"
                  >
                    <option value="Choice 1" >Computer</option>
                    <option value="Choice 2">printer</option>
                    <option value="Choice 3">Notebook</option>
                    <option value="Choice 4">Other</option>
                    <option value="Choice 5">Others</option>
                  </select>
              </div>
              </div>
            
              <div class="row">
                <div class="col-sm-12">
                  <label class="mt-4 ms-0">Description</label>
                  <material-textarea
                    id="Description"
                    v-model="Description"
                    
                  />
                </div>
                <div class="col-sm-12">
                  <label class="mt-2 ms-0">Order By</label>
                    <select
                    id="choices-order-edit"
                    v-model="choice_order"
                    class="form-control"
                    name="choices-order"
                  >
                    <option value="Choice 1" >Butter</option>
                    <option value="Choice 2">Cherry</option>
                    <option value="Choice 3">SomO</option>
                    <option value="Choice 4">Pemisa</option>
                    <option value="Choice 5">Others</option>
                  </select>
                  <label class="mt-2 ms-0">Owner</label>
                    <select
                    id="choices-order-owner"
                    v-model="choice_owner"
                    class="form-control"
                    name="choices-order"
                  >
                    <option value="Choice 1" >Butter</option>
                    <option value="Choice 2">Cherry</option>
                    <option value="Choice 3">SomO</option>
                    <option value="Choice 4">Pemisa</option>
                    <option value="Choice 5">Others</option>
                  </select>
                 <label class="mt-2 ms-0">Shop</label>
                  <select
                  id="choices-shop-edit"
                    v-model="choices_shop"
                  >
                    <option value="Choice 1">Black Shop </option>
                    <option value="Choice 2">White Shop</option>
                    <option value="Choice 3">Blue Shop</option>
                    <option value="Choice 4">Orange Shop</option>
                    <option value="Choice 5">Green Shop</option>
                  </select>
                  <material-button
                  class="mt-2 mb-0 btn bg-gradient-success ms-lg-auto">Save
                  </material-button>
                </div>
              </div>
            </div>
          </div>
        </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable prettier/prettier */
  import Quill from "quill";
  import Choices from "choices.js";
  import MaterialInput from "@/components/MaterialInput.vue";
  import MaterialTextarea from "@/components/MaterialTextarea.vue";
  import MaterialButton from "@/components/MaterialButton.vue";
  import Swal from 'sweetalert2';
  import axios from "axios";
import Dropzone from "dropzone"; // Make sure Dropzone is properly imported

  export default {
    name: "EditEquip",
    components: { MaterialInput, MaterialTextarea ,MaterialButton},
    data() {
      return {
        imageUrl: require("@/assets/img/products/product-11.jpg"),
        name_th:'',
        name_en:'',
        serial:'',
        DateBuy:'',
        price:0,
        choices_Unit:'',
        choices_type:'',
        Description:'',
        choice_order:'',
        choices_shop:'',
      };
    },
    mounted() {
      if (document.getElementById("edit-description-edit")) {
        new Quill("#edit-description-edit", {
          theme: "snow",
        });
      }
      this.getChoices("choices-Unit")
      this.getChoices("choices-type-edit");
      this.getChoices("choices-shop-edit");
      this.getChoices("choices-order-edit");
      this.getChoices("choices-order-owner");
  
      if (document.getElementById("choices-tags-edit")) {
        var tags = document.getElementById("choices-tags-edit");
        const examples = new Choices(tags, {
          removeItemButton: true,
          allowHTML: true,
        });
  
        examples.setChoices(
          [
            {
              value: "One",
              label: "Expired",
              disabled: true,
            },
            {
              value: "Two",
              label: "Out of Stock",
              selected: true,
            },
          ],
          "value",
          "label",
          false
        );
      }
       // Initialize Dropzone
    const myDropzone = new Dropzone("#productImg");
    myDropzone.on("addedfile", (file) => {
      console.log(`File added: ${file.name}`);
    });
    },
    methods: {
      onFileChange(event) {
        const file = event.target.files[0];
        if (file && file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageUrl = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      },
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      getChoices(id) {
        if (document.getElementById(id)) {
          var element = document.getElementById(id);
          return new Choices(element, {
            searchEnabled: false,
            allowHTML: true,
          });
        }
      },
      async handleSubmit() {
      const payload = {

        name_th: this.name_th,
        name_en: this.name_en,
        serial_no: this.serial,
        purchase_date:this.DateBuy,
        price:this.price,
        type:1,
        description:this.Description,
        owner:"SS-ST2024-0001",
        store_id:4,
        description_status:"",
        }
        console.log();
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        };

      const result = await axios.post("https://uat-api.susenginetech.com/api/v1/equipment/add",payload,config)
      if(result.data.isError){
            Swal.fire({
              text: result.data.errorMessage,
              icon: "error"
            })
          }else{
            Swal.fire({
              title: "submit successful",
              icon: "success"
            })
            this.$router.push("/adminpage/eqiupment");
          }
    }
    },
  };
  </script>
  
  <style>
  .text-right {
    text-align: right;
  }
  .card {
    margin-bottom: 20px;
  }
  </style>
  