<template>
<div class="container-fluid">
      <div class="page-header border-radius-xl mt-7">
        
      </div>
      <div class="card card-body mx-3 mx-md-4 mt-n6">
      
          <div class="col-auto">
          </div>
          <div class="col-auto my-auto">
            <h3 class="mb-3 ms-3">Salary</h3>
            <p class="mb-0 ms-3 font-weight-normal text-sm">description</p>
<div class="row">
          <default-statistics-card
            class="col col-lg-3  "
            title="มูลค่าต่อปี"
            count="60,230,220"
            :percentage="{ color: 'success' }"
            menu="6 May - 7 May"
          />
          <default-statistics-card
            class="col col-lg-3"
            title="มูลค่าต่อเดือน"
            count="600,000"
            :percentage="{ color: 'success'}"
            menu="9 June - 12 June"
          />
          <default-statistics-card
            class="col col-lg-3"
            title="มูลค่าสุทธิ"
            count="600,000"
            :percentage="{ color: 'success'}"
            menu="9 June - 12 June"
          />
        </div>
          </div>
      </div></div>
    <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">รายการเงินเดือน เดือนปัจจุบัน</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search0" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>Salary</th>
                  <th>OT</th>
                  <th>Bonus</th>
                  <th>Sum</th>
                  <th>Date</th>
                  <th>Other</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Tiger Nixon</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Herrod Chandler</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/05</td>

                  
                </tr>
               
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Brielle Williamson</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/07/25</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Rhona Davidson</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/06/15</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">หทัย เรือสุวรรณ</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/12/02</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Airi Satou</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2008/11/28</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Cedric Kelly</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/03/29</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Ashton Cox</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2009/01/12</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                 </td>
                  <td class="text-sm font-weight-normal"> Garrett Winters</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/07/25</td>
                 
                  
                </tr>
                
               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>     
<div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">รายการเงินเดือน ปีปัจจุบัน</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search1" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>Salary</th>
                  <th>OT</th>
                  <th>Bonus</th>
                  <th>Sum</th>
                  <th>Date</th>
                  <th>Other</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Tiger Nixon</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Herrod Chandler</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/05</td>

                  
                </tr>
               
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Brielle Williamson</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/07/25</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                 </td>
                  <td class="text-sm font-weight-normal"> Rhona Davidson</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/06/15</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">หทัย เรือสุวรรณ</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/12/02</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Airi Satou</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2008/11/28</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Cedric Kelly</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/03/29</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Ashton Cox</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2009/01/12</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Garrett Winters</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/07/25</td>
 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 
    
<div class="row">
          <default-statistics-card
            class="col col-lg-3  "
            title="มูลค่าต่อปี"
            count="2,200,000"
            :percentage="{ color: 'success' }"
          
            
          />
</div>
<div class="row">
  <h6 class="row">เดือน</h6>
          
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 1"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
            title="เดือน 2"
            count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 3"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 4"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 5"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 6"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 7"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 8"
          count="200,000.00 บาท"

            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 9"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 10"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 11"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 12"
          count="200,000.00 บาท"
          :percentage="{ color: 'success'}"
            
          />
    </div>
    
<div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="col-3 mb-4">
  <label for="choices-year" class="form-label ms-6">Year</label>
  <select
    id="choices-year"
    class="form-select ms-6"
    name="choices-year"
  >
    <option value="choich1">2567</option>
    <option value="choich2">2566</option>
    <option value="choich3">2565</option>
    <option value="choich4">2564</option>
    <option value="choich5">2563</option>
  </select>
</div>
          <div class="card-header">
            <h5 class="mb-0">รายการเงินเดือน ปีปัจจุบัน</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search2" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>Salary</th>
                  <th>OT</th>
                  <th>Bonus</th>
                  <th>Sum</th>
                  <th>Date</th>
                  <th>Other</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Tiger Nixon</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Herrod Chandler</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/05</td>

                  
                </tr>
               
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Brielle Williamson</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/07/25</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                 </td>
                  <td class="text-sm font-weight-normal"> Rhona Davidson</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/06/15</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">หทัย เรือสุวรรณ</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/12/02</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Airi Satou</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2008/11/28</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Cedric Kelly</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/03/29</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Ashton Cox</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2009/01/12</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Garrett Winters</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/07/25</td>
 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 

<div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="col-3 mb-4">
  <label for="choices-year" class="form-label ms-4">Month</label>
  <select
    id="choices-year"
    class="form-select ms-4"
    name="choices-year"
  >
    <option value="choich1">1</option>
    <option value="choich2">2</option>
    <option value="choich3">3</option>
    <option value="choich4">4</option>
    <option value="choich5">5</option>
    <option value="choich1">6</option>
    <option value="choich2">7</option>
    <option value="choich3">8</option>
    <option value="choich4">9</option>
    <option value="choich5">10</option>
    <option value="choich1">11</option>
    <option value="choich1">12</option>
    
  </select>
</div>
          <div class="card-header">
            <h5 class="mb-0">รายการเงินเดือน ปีปัจจุบัน</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search3" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>Salary</th>
                  <th>OT</th>
                  <th>Bonus</th>
                  <th>Sum</th>
                  <th>Date</th>
                  <th>Other</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Tiger Nixon</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Herrod Chandler</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/05</td>

                  
                </tr>
               
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Brielle Williamson</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/07/25</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Rhona Davidson</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/06/15</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">หทัย เรือสุวรรณ</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/12/02</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                 </td>
                  <td class="text-sm font-weight-normal"> Airi Satou</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2008/11/28</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Cedric Kelly</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2012/03/29</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Ashton Cox</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2009/01/12</td>

                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="user"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Garrett Winters</td>
                  <td class="text-sm font-weight-normal">18k</td>
                  <td class="text-sm font-weight-normal">1k</td>
                  <td class="text-sm font-weight-normal">0</td>
                  <td class="text-sm font-weight-normal">19k</td>
                  <td class="text-sm font-weight-normal">2011/07/25</td>
 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 
</template>
<script>
    import sophie from "@/assets/img/kal-visuals-square.jpg";
    import marie from "@/assets/img/marie.jpg";
    import ivana from "@/assets/img/ivana-square.jpg";
    import peterson from "@/assets/img/team-4.jpg";
    import nick from "@/assets/img/team-3.jpg";
    import DefaultStatisticsCard from "/src/views/dashboards/components/DefaultStatisticsCard.vue";
    import { DataTable } from "simple-datatables";
    import MaterialAvatar from "/src/components/MaterialAvatar.vue";
    import user from "/src/assets/icon/user.png";

    export default {
    components: {
      DefaultStatisticsCard,MaterialAvatar
    },
    data() {
      return {
        sophie,
        marie,
        ivana,
        peterson,
        nick,
        user,
        
        dropdownOptions: [], // Define this if you have dropdown options
        
      }
    },
    mounted() {
    // // eslint-disable-next-line no-unused-vars
    // const dataTableBasic = new DataTable("#datatable-basic", {
    //   searchable: false,
    //   fixedHeight: true,
    // });

    // eslint-disable-next-line no-unused-vars
    const dataTableSearch = new DataTable("#datatable-search0", {
      searchable: true,
      fixedHeight: true,
    });
   // eslint-disable-next-line no-unused-vars
   const dataTableSearch1 = new DataTable("#datatable-search1", {
      searchable: true,
      fixedHeight: true,
    });
   // eslint-disable-next-line no-unused-vars
   const dataTableSearch2 = new DataTable("#datatable-search2", {
      searchable: true,
      fixedHeight: true,
    });
    // eslint-disable-next-line no-unused-vars
   const dataTableSearch3 = new DataTable("#datatable-search3", {
      searchable: true,
      fixedHeight: true,
    });
  },
}
</script>